import {
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";
import {
    AvgPriceEditDto,
    CreateOrEditScenarioDto, ScenarioPricesMeanDto, ScenariosServiceProxy,
} from "@shared/service-proxies/service-proxies";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'avgPriceEdit',
    templateUrl: './avg-price-edit.component.html',
    styleUrl: './avg-price-edit.component.css'
})
export class AvgPriceEditComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input('title') title: string = 'Values';
    @Input('data') data: CreateOrEditScenarioDto;
    @Input('scenarioPricesMean') scenarioPricesMean: ScenarioPricesMeanDto;
    @Input('avgPriceEditData') avgPriceEditData: AvgPriceEditDto[] = [];
    active = false;
    saving = false;
    isAncillaryServicesPrice: boolean = false;
    isWholesaleMarketPrice: boolean = false;

    constructor(
        private _scenariosServiceProxy: ScenariosServiceProxy,
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.data.priceId != undefined || this.data.priceId != null)
            this.isWholesaleMarketPrice = true;
        else
            this.isWholesaleMarketPrice = false;

        if (this.data.ancillaryServicesPriceId != undefined || this.data.ancillaryServicesPriceId != null)
            this.isAncillaryServicesPrice = true;
        else
            this.isAncillaryServicesPrice = false;

        if (this.avgPriceEditData.length == 0) {
            let _investmentLifetime = (this.data.investmentLifetime as number)
            for (let i = 0; i < _investmentLifetime; i++) {
                let value = (i + 1);
                let item = new AvgPriceEditDto();
                item.year = value;
                if (this.isWholesaleMarketPrice && this.scenarioPricesMean) {
                    item.damPrice = this.scenarioPricesMean.wholesaleMarketPriceMean;
                }
                if (this.isAncillaryServicesPrice && this.scenarioPricesMean.ancillaryServicesPriceMean) {
                    item.pfcPrice = this.scenarioPricesMean.ancillaryServicesPriceMean;
                }

                this.avgPriceEditData.push(item)
            }
        }

    }

    ngOnInit(): void {
    }

    close(): void {
        abp.event.trigger("scenario-avg-price-close", this.data);
    }

    savePrice(): void {
        let isControl = false;
        let _this = this;
        for (let i = 0; i < this.avgPriceEditData.length; i++) {
            let item = this.avgPriceEditData[i];
            if (_this.isWholesaleMarketPrice) {
                if (item.damPrice < 0 || item.damPrice == null) {
                    _this.notify.error("Must be greater than 0");
                    isControl = false;
                    break;
                }
            }
            if (_this.isAncillaryServicesPrice) {
                if (item.pfcPrice < 0 || item.pfcPrice == null) {
                    _this.notify.error("Must be greater than 0");
                    isControl = false;
                    break;
                }
            }
            isControl = true;
        }

        if (isControl) {
            this.data.avgPrice = this.avgPriceEditData
            this._scenariosServiceProxy
                .create(this.data)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                    }),
                )
                .subscribe(() => {
                    abp.event.trigger("scenario-avg-price-update", this.data);
                });
        }

    }
}

<ng-container *ngIf="data">
    <h3>{{title}}</h3>
    <div class="modal-content" style="border:none !important;border-radius:0 !important;box-shadow:none !important;">
        <div class="modal-body" style="position: fixed; top: 0px;">
            <div class="my-3">
                <table>
                    <thead>
                    <th>Year</th>
                    <th *ngIf="isWholesaleMarketPrice" style="text-align: center;">Avg DAM Price</th>
                    <th *ngIf="isAncillaryServicesPrice" style="text-align: center;">Avg PFC Price</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of avgPriceEditData; ">
                        <td>{{item.year}}</td>
                        <td *ngIf="isWholesaleMarketPrice">
                            <input type="number" class="form-control" [(ngModel)]="item.damPrice" required>
                        </td>
                        <td *ngIf="isAncillaryServicesPrice">
                            <input type="number" class="form-control" [(ngModel)]="item.pfcPrice" required>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-5" style="display: flex; align-items: center; justify-content: space-between;width: 100%;">
                <button [disabled]="saving" type="button" class="btn btn-secondary" (click)="close()">{{l("Back")}}</button>
                <button type="button" class="btn btn-primary blue" (click)="savePrice()" [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Run Scenario")}}</span></button>
            </div>
        </div>
    </div>

</ng-container>
